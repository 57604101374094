
import { defineComponent, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { DownloadOutlined } from "@ant-design/icons-vue";
import useDealerPayout from "@/hooks/payment/Mgmt/PendingApproval/PayoutRound/useDealerPayout";
import { ApprovalProps } from "@/views/Payment/Mgmt/PendingApproval/PayoutRound/type";
import { fetchPaymentApprove, fetchPaymentReject } from "@/API/payment";
import EntityWrapper from "@/views/Payment/Mgmt/components/entityWrapper.vue";
import Entity from "@/views/Payment/Mgmt/components/entity.vue";
import ApprovalModal from "@/views/Payment/components/approvalModal.vue";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import { Modal } from "ant-design-vue";
import downloadFile from "@/utils/payment/downloadFile";
const COLUMNS = [
  {
    title: "Payout Code",
    dataIndex: "payoutCode",
    className: "column-no-wrap",
  },
  {
    title: "Payout Amount w/o VAT",
    dataIndex: "payoutAmountWVoat",
    align: "right",
    slots: { customRender: "formatThousand" },
  },
  {
    title: "Period Amount w VAT",
    dataIndex: "payoutAmountWVat",
    align: "right",
    slots: { customRender: "formatThousand" },
  },
];
export default defineComponent({
  name: "paymentTracking",
  props: {},
  setup() {
    const route = useRoute();
    const payoutRound = ref<string>(route.query.payoutRound as string);
    const {
      payoutList,
      tableList,
      payoutCodes,
      getPayoutList,
      getRowClass,
      getData,
    } = useDealerPayout(payoutRound.value ?? undefined);
    getData(payoutRound.value, "pendingApproval");

    //#region [payround下拉列表相关]
    getPayoutList();
    const handlePayroundChange = (val: string) => {
      payoutRound.value = val;
    };
    //#endregion

    //#region [审批列表相关]
    const tableData = reactive({
      columns: COLUMNS,
      data: tableList,
    });
    const rowSelection = reactive<{
      selectedRowKeys: string[];
      selectedRowData: ApprovalProps[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: string[], selectedRows: ApprovalProps[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
    });
    const clearSelection = () => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };
    //#endregion

    //#region [审批相关]
    const tableModalVisible = ref<boolean>(false);
    const approvalModalVisible = ref<boolean>(false);
    const approvalType = ref<string | null>(null);
    const titleMap = reactive({
      reject: "reject",
      approve: "approve",
    });
    const handleReject = () => {
      tableModalVisible.value = true;
      approvalType.value = "reject";
    };
    const handleApprove = () => {
      tableModalVisible.value = true;
      approvalType.value = "approve";
    };
    const handleCancel = () => {
      approvalType.value = "";
      tableModalVisible.value = false;
      clearSelection();
    };
    const handleConfirm = () => {
      // approvalType.value = "";
      approvalModalVisible.value = true;
    };
    // 当审批和拒绝时刷新列表数据
    const entityRef = ref();
    const refreshPageData = () => {
      entityRef.value.refreshData();
    };
    const handleCommentsConfirm = (comments: string, next: Function) => {
      if (approvalType.value === "approve") {
        fetchPaymentApprove(
          rowSelection.selectedRowData.map((item) => {
            return {
              approveComment: comments,
              delegationUserId: item.delegationUserId,
              id: item.id,
              requestUserId: item.requestUserId,
            };
          })
        )
          .then((res) => {
            // message.success('Approved successfully');
            Modal.info({
              title: "Tips",
              content: "Successfully Approve！", //res.data.message
            });
            tableModalVisible.value = false;
            approvalModalVisible.value = false;
            next();
            clearSelection();
            getPayoutList();
            // 刷新页面数据
            refreshPageData();
          })
          .catch(() => {
            tableModalVisible.value = false;
            approvalModalVisible.value = false;
            next();
            clearSelection();
          });
      } else {
        fetchPaymentReject(
          rowSelection.selectedRowData.map((item) => {
            return {
              rejectComment: comments,
              delegationUserId: item.delegationUserId,
              id: item.id,
              requestUserId: item.requestUserId,
            };
          })
        )
          .then((res) => {
            // message.success('Rejected successfully');
            Modal.info({
              title: "Tips",
              content: "Successfully Reject！", //res.data.message
            });
            tableModalVisible.value = false;
            approvalModalVisible.value = false;
            next();
            clearSelection();
            getPayoutList();
            // 刷新页面数据
            refreshPageData();
          })
          .catch(() => {
            tableModalVisible.value = false;
            approvalModalVisible.value = false;
            next();
            clearSelection();
          });
      }
    };
    // 下载 sample check form
    const handleDownload = () => {
      // getProgramOfferTypeByPayoutRound(payoutRound.value).then((res: any) => {
      //   downloadFile({
      //     url: `/claimapi/paymentDownload/downloadSampleCheckForm`,
      //     method: "post",
      //     params: {
      //       payoutRound: payoutRound.value,
      //       programCodeOfferTypeReqVOList: res,
      //     },
      //   });
      // });
      // 12.8修改
      const params = {
        url: `/ptapi/export/downloadPayoutFilesByPayoutCode?payoutRound=${payoutRound.value}`,
        method: "post",
        params: payoutCodes.value,
      };
      downloadFile(params);
    };
    //#endregion
    return {
      payoutRound,
      payoutList,
      handlePayroundChange,
      tableModalVisible,
      handleReject,
      handleApprove,
      handleCancel,
      handleConfirm,
      ...toRefs(tableData),
      rowSelection,
      getRowClass,
      approvalModalVisible,
      handleCommentsConfirm,
      approvalType,
      toThousandsAndTwoDecimal,
      titleMap,
      entityRef,
      handleDownload,
    };
  },
  components: {
    DownloadOutlined,
    EntityWrapper,
    Entity,
    ApprovalModal, // 审批组件
  },
});
